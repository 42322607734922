package tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_room_view

import tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.start_consultation.StartConsultationData

sealed class ChatRoomState {
    data object InitialState : ChatRoomState()
    data class OnErrorState(val errorMsg: String) : ChatRoomState()
    data class OnLoading(val isLoading: Boolean) : ChatRoomState()
    data class OnChatStart(val chatData: StartConsultationData) : ChatRoomState()
    data object OnEndChat: ChatRoomState()
    data object OnEndChatSuccess: ChatRoomState()
}