package tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_waiting_room

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.lightColorScheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import co.touchlab.kermit.Logger
import tta.destinigo.talktoastro.feature_chat_consultation_new.timer.CountdownTimer
import tta.destinigo.talktoastro.feature_chat_consultation_new.timer.formatTime
import tta.destinigo.talktoastro.shared.persentation.CommonTextButton

@Composable
fun ChatWaitingScreen(
    chatWaitingComponent: ChatWaitingComponent
) {

    val countdownTimer = remember { CountdownTimer(durationMillis = 120000L) }
    countdownTimer.start()
    val timeLeft by countdownTimer.timeLeft.collectAsState(initial = 120000L)

    LaunchedEffect(Unit) {
        countdownTimer.start()
    }



    DisposableEffect(Unit) {
        onDispose {
            countdownTimer.cleanup()
        }
    }

    MaterialTheme(colorScheme = lightColorScheme()) {
        Column(
            verticalArrangement = Arrangement.Center,
            modifier = Modifier.fillMaxSize()
                .background(MaterialTheme.colorScheme.primaryContainer),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Spacer(modifier = Modifier.height(32.dp))

            Text(
                text = "Connecting...",
                style = MaterialTheme.typography.headlineMedium,
                color = MaterialTheme.colorScheme.onPrimaryContainer
            )

            Text(
                text = "You will join the chat room shortly\nMax Chat Waiting Period",
                style = MaterialTheme.typography.bodyMedium,
                color = MaterialTheme.colorScheme.onPrimaryContainer,
                textAlign = TextAlign.Center,
                modifier = Modifier.padding(vertical = 8.dp)
            )

            Text(
                text = "Remaining Time",
                style = MaterialTheme.typography.titleLarge,
                color = MaterialTheme.colorScheme.onPrimaryContainer,
                modifier = Modifier.padding(vertical = 8.dp)
            )

            // Display remaining time
            Text(
                text = formatTime(timeLeft),
                style = MaterialTheme.typography.bodyLarge,
                color = MaterialTheme.colorScheme.onPrimaryContainer
            )

            Spacer(modifier = Modifier.height(32.dp))

            ChatInfoCard(
                readerProfession = chatWaitingComponent.expertSkills,
                readerProfile = chatWaitingComponent.expertImage,
                readerName = chatWaitingComponent.expertName,
                maxChatDuration = chatWaitingComponent.chatDuration
            )
            Logger.d { "image ${chatWaitingComponent.expertImage}" }
            Spacer(modifier = Modifier.height(32.dp))

            CommonTextButton(
                buttonText = "Cancel", modifier = Modifier.fillMaxWidth()
                    .height(48.dp), onClick = {
                    chatWaitingComponent.onEndConsultation()
                }, icon = null, isLoading = false
            )

            Spacer(modifier = Modifier.height(16.dp))

            FooterText()

            Spacer(modifier = Modifier.height(16.dp))
        }
    }
}
