package tta.destinigo.talktoastro.feature_chat_consultation_new.domain

import tta.destinigo.talktoastro.feature_chat_consultation_new.data.api.ChatConsultationApi
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.request.StartChatRequest
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.end_consultation.ChatConsultationEndData
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.start_consultation.StartConsultationData
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.request.PlaceChatRequestBody
import tta.destinigo.talktoastro.shared.remote.Resources

class ChatRoomRepo(
    private val api: ChatConsultationApi
) {

    fun startChat(request: StartChatRequest): Flow<Resources<StartConsultationData>> {
        return flow {
            emit(Resources.Loading())
            val response = api.startChatConsultation(request)
            emit(response)
            emit(Resources.Loading(false))
        }
    }

    fun endChat(request: StartChatRequest): Flow<Resources<ChatConsultationEndData>> {
        return flow {
            emit(Resources.Loading())
            val response = api.endChatConsultation(request)
            emit(response)
            emit(Resources.Loading(false))
        }
    }

    fun saveChat(request: PlaceChatRequestBody): Flow<Resources<ChatConsultationEndData>> {
        return flow {
            emit(Resources.Loading())
            val response = api.saveChatConsultation(request)
            emit(response)
            emit(Resources.Loading(false))
        }
    }
}