package tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_duration_dailog

import androidx.compose.runtime.Composable
import com.arkivanov.decompose.ComponentContext
import tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_bottom_sheet_info.ChatConsultationBottomSheet
import tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_bottom_sheet_info.ChatConsultationComponent
import tta.destinigo.talktoastro.shared.data.RechargeData
import tta.destinigo.talktoastro.shared.data.RequestConsultant
import tta.destinigo.talktoastro.shared.local.UserInfoProvider
import tta.destinigo.talktoastro.shared.persentation.LoginDialog

@Composable
fun ChatConsultationStarter(
    isEmergency:Boolean = false,
    chatDetails: RequestConsultant,
    onLoginClicked: () -> Unit,
    onDismiss: () -> Unit,
    onStartChatConsultation: (String, String,String) -> Unit,
    onRechargeAgain: (RechargeData?) -> Unit,
    context: ComponentContext,
    lowBalanceRecharge:() -> Unit,
) {
    val isUserLogin = UserInfoProvider.getIsLogin()

    if (isUserLogin.not()) {
        LoginDialog(onLoginClicked = {
            onLoginClicked.invoke()
        }, onSignUpClicked = {
            onLoginClicked.invoke()
        }, onDismiss = {
            onDismiss.invoke()
        })
        return
    }

    ChatConsultationBottomSheet(
        consultationComponent = ChatConsultationComponent(context = context,
            _isEmergency=  isEmergency,
            _expertId = chatDetails.id.toString(),
            _expertName = chatDetails.full_name.toString(),
            _expertImage = chatDetails.profile_image_url ?: chatDetails.profile_image ?: "",
            _expertSkills = chatDetails.skills.toString(),
            onClose = {
                onDismiss.invoke()
            },
            onConnect = { duration, category, chatType ->
                onStartChatConsultation.invoke(duration,category, chatType)
            },
            onRechargeAgain = { rechargeData ->
                onRechargeAgain.invoke(rechargeData)
            },
            lowBalanceRecharge = {
                lowBalanceRecharge.invoke()
            }
            )
    )

}