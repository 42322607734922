package tta.destinigo.talktoastro.feature_chat_consultation_new.data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class StartChatRequest(
    @SerialName("chat_id")val expertId:String,
    @SerialName("type")val type:String?
)
