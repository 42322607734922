package tta.destinigo.talktoastro.feature_chat_consultation_new.data.response

import tta.destinigo.talktoastro.feature_chat_consultation_new.domain.Sender
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ChatMessage(
    //0- concern Image
//1- Normal Msg
//2- Concern Answer
//3- Concern Question
//4- Normal Image
    @SerialName("id") val id: Int? = null,
    @SerialName("msg") val msg: String,
    @SerialName("sender") var sender: Int,       //User -1, Expert -  0
    @SerialName("date_time") val date: String,
   // @SerialName("msg_type") val msgType: MsgType?,
    @SerialName("msg_type") val msgType: Int?,
    @SerialName("concern") val userConcern: List<ChatConcernItem>?,
    @SerialName("attachment_status") val isAttachmentUploaded: Boolean = false
){
    // Convert the sender Int value to Sender enum
    val senderEnum: Sender
        get() = if (sender == 1) Sender.USER else Sender.EXPERT
}

//@Serializable
//data class ChatMessage(
//    @SerialName("id") val id: Int? = null,
//    @SerialName("msg") val msg: String,
//    @SerialName("date_time") val date: String,
//    @SerialName("msg_type") val msgType: MsgType?,
//    @SerialName("concern") val userConcern: List<ChatConcernItem>?,
//    @SerialName("attachment_status") val isAttachmentUploaded: Boolean = false,
//
//    // Internal field for storing the sender's numeric value (0 or 1)
//    @SerialName("sender") private var _sender: Int  // Store 0 or 1 in serialized data
//) {
//    // Enum for sender (User or Expert)
//    var sender: Sender
//        get() = if (_sender == 1) Sender.USER else Sender.EXPERT  // Convert 1 -> USER, 0 -> EXPERT
//        set(value) {
//            _sender = if (value == Sender.USER) 1 else 0  // Set _sender to 1 or 0 based on enum value
//        }
//}


//@Serializer(forClass = Sender::class)
//object SenderSerializer : KSerializer<Sender> {
//    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("Sender", PrimitiveKind.INT)
//
//    override fun serialize(encoder: Encoder, value: Sender) {
//        encoder.encodeInt(value.value)  // Store 0 for EXPERT, 1 for USER
//    }
//
//    override fun deserialize(decoder: Decoder): Sender {
//        return when (val value = decoder.decodeInt()) {
//            1 -> Sender.USER
//            0 -> Sender.EXPERT
//            else -> throw SerializationException("Unknown value: $value")
//        }
//    }
//}
