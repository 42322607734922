package tta.destinigo.talktoastro.feature_chat_consultation_new.extenstion

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.ui.Modifier

fun WindowWidthSizeClass.getMaxInputAbleWidthModifier(
    medium: Float = 0.6f, expand: Float = 0.45f
): Modifier {
    return when (this) {
        WindowWidthSizeClass.Compact -> Modifier.fillMaxWidth()
        WindowWidthSizeClass.Medium -> Modifier.fillMaxWidth(medium)
        else -> Modifier.fillMaxWidth(expand)
    }
}

fun Double.toFormattedString(): String {
    return if (this % 1.0 == 0.0) {
        this.toInt().toString()
    } else {
        val decimalPart = (this * 100).toInt() % 100
        if (decimalPart == 0) {
            this.toInt().toString()
        } else {
            formatDecimal(this, "#.##").trimEnd('0').trimEnd('.')
        }
    }
}
