package tta.destinigo.talktoastro.feature_chat_consultation_new.domain

import co.touchlab.kermit.Logger
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.place_chat_concern.ChatUserConcernData
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.api.ChatConsultationApi
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.request.PlaceChatRequestBody
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.api.AstrologyApi
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.request.ConcernQuestionRequest
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.request.GeoDetailsRequest
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.request.TimeZoneRequest
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.concern_question.ConcernQuestions
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.geo_details.GeoName
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import tta.destinigo.talktoastro.shared.remote.Resources

class ChatConcernRepo(
    private val api: ChatConsultationApi,
    private val geoApi: AstrologyApi
) {

    fun getChatConcernQuestions(request: ConcernQuestionRequest): Flow<Resources<List<ConcernQuestions>>> {
        return flow {
            emit(Resources.Loading())
            val response = api.getChatConcernQuestion(request)

            if (response is Resources.Success<*>) {
                Logger.d("Repo Success -> ${response.data}")
                emit(Resources.Success(response.data))
                emit(Resources.Loading(false))
                return@flow
            }
            if (response is Resources.Error<*>) {
                Logger.d("Repo Error -> ${response.data}")
                emit(Resources.Error(response.msg))
                emit(Resources.Loading(false))
            }
        }
    }

    fun placeWithChatConcern(request: PlaceChatRequestBody): Flow<Resources<ChatUserConcernData>> {
        return flow {
            emit(Resources.Loading())
            val response = api.placeChatWithConcern(request)
            Logger.d("Repo -> $response")
            emit(response)
            emit(Resources.Loading(false))
        }
    }

    fun getGeoDetails(request: GeoDetailsRequest): Flow<Resources<List<GeoName>>> {
        return flow {
            emit(Resources.Loading())
            val response = geoApi.getGeoDetails(request)
            Logger.d("Repo -> $response")
            emit(response)
            emit(Resources.Loading(false))
        }
    }

    fun getTimeZone(request: TimeZoneRequest): Flow<Resources<String>> {
        return flow {
            emit(Resources.Loading())
            val response = geoApi.getTimeZone(request)
            Logger.d("Repo -> $response")
            emit(response)
            emit(Resources.Loading(false))
        }
    }

}