package tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.place_chat_concern

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


/**
 * Created by Prince on 13-06-2023.
 * @Author Suraj Sharma
 */
@Serializable
data class ChatUserConcernData(
    @SerialName("chat_id") val chatId: Int?,
    @SerialName("channel_name") val channelName: String?,
    @SerialName("agora_user_uid") val agoraUserId: String?,
    @SerialName("agora_user_token") val agoraToken: String?,
    @SerialName("activestatus") val activeStatus: String?,
)
