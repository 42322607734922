package tta.destinigo.talktoastro.feature_chat_consultation_new

import io.ktor.client.call.body
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.serialization.json.Json
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.ChatMessage
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.save_chat.SaveChatConversationRequest
import tta.destinigo.talktoastro.shared.config.BASE_URL
import tta.destinigo.talktoastro.shared.remote.KtorApi

actual suspend fun saveChatMessage(
    url: String,
    chatId: String,
    list: List<ChatMessage>
): String {
    val ktorApi = object : KtorApi() {}

    // Create the request object with the messages list and chatId
    val request = SaveChatConversationRequest(msg = list.toTypedArray(), chatId)

    // Debug log to check the serialized JSON
    val jsonBody = Json.encodeToString(SaveChatConversationRequest.serializer(), request)
    console.log("Serialized Request Body: $jsonBody")

    return try {
        // Perform POST request with JSON body using the KtorApi instance's client
        val response: String = ktorApi.client.post {
            url("${BASE_URL}$url") // Construct the full URL using BASE_URL
            contentType(ContentType.Application.Json)
            setBody(request)
        }.body()

        response
    } catch (e: Exception) {
        console.log("API call failed: ${e.message}")
        "Error: ${e.message}" // Return error message if something goes wrong
    }
}